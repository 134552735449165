<template>
  <span
    class="prime-vip-svg"
    :class="{'prime-middle': isMiddle}"
    :style="{width: width+'px', height: height+'px', top: top+'px'}"
  >
    <img :src="`${PUBLIC_CDN}/she_dist/images/prime/sui_icon_paidmember-b56902feb1.png`" />
  </span>
</template>

<script>
export default {
  name: 'PrimeVipIcon',
  props: {
    width: {
      type: [Number, String],
      default: '15'
    },
    height: {
      type: [Number, String],
      default: '15'
    },
    top: {
      type: [Number, String],
      default: 0
    },
    isMiddle: {
      type: Number,
      default: 0
    },
    publicCdn: {
      type: String,
      default: ''
    },
  },
  computed: {
    PUBLIC_CDN(){
      return this.publicCdn || gbCommonInfo.PUBLIC_CDN || ''
    }
  }
}
</script>

<style lang="less">
.prime-vip-svg{
  display: inline-flex;
  position: relative;
  img{
    width: 100%;
  }
}
.prime-middle {
  vertical-align: text-bottom;
}
</style>
