import { defineAsyncComponent } from 'vue'

function creator() {
  return defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "Home_Address" */ './index_v2.vue'
    )
  )
}

export default creator()
