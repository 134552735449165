<template>
  <div
    class="normal-tax-item"
    :identification="identification"
  >
    <span
      v-if="tax.taxType == 1"
      class="normal-tax-item__left"
    >
      <template v-if="isShowCaGst">
        {{ language.SHEIN_KEY_PC_21334 }}
        <s-popover
          :hide-close-icon="true"
          trigger="hover"
          placemen="bottom"
        >
          <span>{{ language.SHEIN_KEY_PC_21335 }}</span>
          <template #reference>
            <sui_icon_doubt_16px_1
              size="14px"
              color="#767676"
            />
          </template>
        </s-popover>
      </template>
      <template v-else>
        {{ language.SHEIN_KEY_PC_16415 }}
      </template>
    </span>
    <!-- 通用展示 -->
    <span
      v-else-if="safeguardTaxesConfig && safeguardTaxesConfig[tax.taxType] && safeguardTaxesConfig[tax.taxType].langKey" 
      class="normal-tax-item__left"
    >
      <span>
        {{ language[safeguardTaxesConfig[tax.taxType].langKey] }}
      </span>
      <s-popover
        hide-close-icon
        trigger="hover"
        placemen="bottom"
      >
        <p v-html="taxTips"></p>
        <template #reference>
          <span 
            v-if="taxTips && currentTaxConfig.showIcon"
            class="normal-tax-item__left-icon"
          >
            <sui_icon_doubt_16px_1
              size="14px"
              color="#767676"
            />
          </span>
        </template>
      </s-popover>
    </span>
    <span class="normal-tax-item__right">
      {{ tax.taxPrice && tax.taxPrice.amountWithSymbol }}
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { getTaxesConfig } from 'public/src/pages/common/helpers.js'
import { sui_icon_doubt_16px_1 } from '@shein-aidc/icon-vue3'

const taxTypes = [2, 3, 4, 5, 6, 7]

const props = defineProps({
  tax: {
    type: Object,
    default: () => {
      return {}
    },
  },
  language: {
    type: Object,
    default: () => {
      return {}
    },
  },
  isShowCaGst: {
    type: Boolean,
    default: false
  },
  countryCode: {
    type: String,
    default: ''
  },
  identification: { // 标识，参与按照数值进行订单明细排序用到, 如果修改【taxTypes】的时候，需要更改组件PriceDetail2 的 priceDetailNameMap的值
    type: String,
    default: ''
  },
})

const rewriteConfig = computed(() => {
  if (props.countryCode === 'BR') {
    return {
      3: {
        _name: 'ICMS',
        langKey: 'SHEIN_KEY_PC_26613'
      }
    }
  }
  if (props.countryCode === 'TR') {
    return {
      7: {
        _name: 'II',
        langKey: 'SHEIN_KEY_PC_32484',
        hintKey: 'SHEIN_KEY_PC_32485',
        showIcon: true
      }
    }
  }
  return {}
})

const safeguardTaxesConfig = computed(() => {
  return getTaxesConfig({ taxTypes, rewriteConfig: rewriteConfig.value })
})

const currentTaxConfig = computed(() => {
  return safeguardTaxesConfig.value[props.tax.taxType]
})

const taxTips = computed(() => {
  const { hintKey } = currentTaxConfig.value
  return props.language[hintKey]
})
</script>

<style lang="less" scoped>
.normal-tax-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 13px;
  font-size: 13px;
  &__left {
    display: flex;
  }

  &__left-icon {
    margin-left: 2px;
  }

  &__icon {
    color: #999;
    font-size: 12px;
  }
}
</style>
